//export let previousType = null;
import noUiSlider from 'nouislider';

export function toggleEditableFields(status) {

    var isDisabled = (status == 'Active' || status == 'Completed')
    console.log('Enable/Disable Fields: ' + isDisabled + ' ' + status);
    $('#priceInputs input').prop('disabled', isDisabled);
    $('#priceInputs .counter').prop('disabled', isDisabled);
    $('#priceInputs .decrement, #priceInputs .increment').prop('disabled', isDisabled);
    $('#priceInputs select option:not(:selected)').prop('disabled', isDisabled);
    $('[data-type] select option:not(:selected)').prop('disabled', isDisabled);
    $('[data-type] .increment,[data-type] .decrement,[data-type] input,[data-type] select').prop('disabled', isDisabled);
    $('#duration').prop('disabled', false);//Bug ?
    $('#type option:not(:selected)').prop('disabled', isDisabled);
    $('#hasProducts').prop('disabled', isDisabled)
    $('input[name="extras[]"]').prop('disabled', isDisabled)    
}

export function resetPriceableFields() {

    console.log('Resetting all fields:');
    $('#priceInputs .price-items').find('input[type=number]').val(0); //bug
    $('#priceInputs .price-items').find('checkbox').val(0);
    $('#duration,#durationMax,#durationOccupants,#occupants').val(0);
    $('#hasProducts').prop('checked', false).val("0");
    $('input[name="extras[]"]').prop('checked', false);
    $('#price').val(0);
    $('#total-2').text(formatCurrency(0.00));
    $('#totalLabel').text(formatCurrency(0.00));

    console.log('Resetting all fields:');   
}

 
export function togglePriceableDisplay(job, type = '') {

    console.log("Toggle Priceable Display: " + type);

     // Initially hide elements where data-type has a value 
    $('#hoursExcededFeedback').hide();
    $('[data-type]').hide();
    $('#priceInputs').hide();

    // Hide all price summary divs
    //$('#priceSummaryItems  div').hide();
    $('#typeIconsWrapper i').hide();


    if (type !== '') {

        // UI: price summary divs
        $('#' + type + 'TypeIcon').show().parent().show();
        $('#typeLabel').parent().show();
        $('#typeLabel').text(type.charAt(0).toUpperCase() + type.slice(1).toLowerCase());
        $('#priceInputs').show()
        $('[data-type="' + type + '"]').show();

        // Handle office fields
        if (type == 'office') {
            // Hide all .price-items cards except #squareMeters
            $('#priceInputs .price-items').hide();
            $('#squareMeters').closest('.price-items').show();

        } else {
            // If type is not office, show all .price-items
            $('#priceInputs .price-items').show();
        }
    }

    // If the type is the same as the previous one, don't do anything
    if (type !== job.type) {
        // Update previousType to the new type
        job.type = type;
    }
}

export function counterHandler() {

    $('.price-items .increment,.price-items .decrement').on('click', function () {
        $(this).closest('.input-group').find('input').trigger('input');
    });

    $('.price-items .counter').on('input', function () {
        let max = parseInt($(this).attr('max')) || 100;
        let currentValue = parseInt($(this).val());
        if (currentValue > max) {
            $(this).val(max);
        }
    });

    $('.price-items .increment').click(function () {
        let counter = $(this).closest('.input-group').find('.counter');
        let counterName = counter.attr('name');
        let step = parseInt(counter.attr('step')) || 1;
        let max = parseInt(counter.attr('max')) || 10000;
        let currentValue = parseInt(counter.val()) + step;
        console.log(`Increment: Step = ${step}, Max = ${max}, Current Value = ${currentValue}, Counter Name = ${counterName}`);

        if (currentValue <= max) {
            counter.val(currentValue);
        }

        // Trigger input event after updating input value
        counter.trigger('input');
    });

    $('.price-items .decrement').click(function () {

        let counter = $(this).closest('.input-group').find('.counter');
        let counterName = counter.attr('name');
        let step = parseInt(counter.attr('step')) || 1;
        let min = parseInt(counter.attr('min')) || 0;
        let currentValue = parseInt(counter.val()) > min ? parseInt(counter.val()) - step : parseInt(counter.val());
        console.log(`Decrement: Step = ${step}, Min = ${min}, Current Value = ${currentValue}, Counter Name = ${counterName}`);

        if (currentValue >= min) {
            //currentValue -= step;
            counter.val(currentValue);
        }

        // Trigger input event after updating input value
        counter.trigger('input');
    });

}

export function updatePriceUI(job, price, duration) {

    // Update Summary
    $.each(job, function (selector, value) {
        if (value && value.length !== 0) {
            $('#' + selector + 'Wrapper').css('opacity', 1);
        } else {
            $('#' + selector + 'Wrapper').css('opacity', 0.4);
        }
    });

    // Update price span
    $('#squareMetersWrapper span').text(job.squareMeters);
    $('#bedroomsWrapper  span').text(job.bedrooms);
    $('#bathroomsWrapper span').text(job.bathrooms);
    $('#kitchensWrapper span').text(job.kitchens);
    $('#livingroomsWrapper span').text(job.livingrooms);
    $('#terracesWrapper span').text(job.terraces);
    $('#otherRoomsWrapper span').text(job.otherRooms);
    $('#extrasWrapper span').text(job.extras.length);
    $('#priceWrapper span').text(formatCurrency(price));
    $('#durationWrapper span').text(duration); // + "hrs"
    $('#total-2').text(formatCurrency(price));
}

export function setJob() {
    return {
        origin: 'client',
        type: String($('#type').val()),
        squareMeters: parseInt($('#squareMeters').val(), 10) || 0,
        bedrooms: parseInt($('#bedrooms').val(), 10) || 0,
        bathrooms: parseInt($('#bathrooms').val(), 10) || 0,
        kitchens: parseInt($('#kitchens').val(), 10) || 0,
        livingrooms: parseInt($('#livingrooms').val(), 10) || 0,
        terraces: parseInt($('#terraces').val(), 10) || 0,
        otherRooms: parseInt($('#otherRooms').val(), 10) || 0,
        durationOccupants: parseInt($('#durationOccupants').val(), 10) || 0,
        occupants: parseInt($('#occupants').val(), 10) || 0,
        hasProducts: $('#hasProducts').is(':checked') ? 1 : 0,
        extras: $('input[name="extras[]"]:checked').map(function () { return $(this).val() }).get(),
    };
}


export function createSlider() {
    //Component: Pay slider
    var slider = $('#slider');
    var min = slider.data('min');
    var max = slider.data('max');

    if (slider.length) {
        noUiSlider.create(slider[0], {
            start: [10, 50],
            connect: true,
            range: {
                'min': min,
                'max': max
            }
        });

        slider[0].noUiSlider.on('update', function (values, handle) {
            var value1 = Math.round(parseFloat(values[0]));
            var value2 = Math.round(parseFloat(values[1]));
            $('#payRateDisplay').text(value1 + '.00 - ' + value2 + '.00');
            $('#min_pay').val(value1);
            $('#max_pay').val(value2);
        });
    }
}

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'EUR',
    currencyDisplay: 'narrowSymbol'
});

export const formatCurrency = (number) => formatter.format(number);





export function updateStepper() {
    $('#stepper [data-step]').hide();

    // Check if the old value is filled for each step
    var steps = $('#stepper [data-step]');
    var stepToShow = 1;

    for (var i = 0; i < steps.length; i++) {
        var inputs = $(steps[i]).find(':input[required]');
        var allFilled = true;

        inputs.each(function () {
            if (!this.value) {
                allFilled = false;
            }
        });

        if (!allFilled) {
            // If not all required fields are filled, this is the step to show
            stepToShow = i + 1;
            break;
        }
    }


    // Show the step where the user left off
    $('#stepper [data-step="' + stepToShow + '"]').show();

    // Listen for changes in the form fields
    $('#stepper :input').change(function () {
        // Get the current step
        var currentStep = $(this).closest('[data-step]');

        // Check if all required form fields in the current step are filled
        var allFilled = true;
        currentStep.find(':input[required]').each(function () {
            if (!this.value) {
                allFilled = false;
            }
        });

        // If all required form fields in the current step are filled
        if (allFilled) {
            // Get the next step
            var nextStep = currentStep.next('[data-step]');

            // Hide the current step and show the next step
            currentStep.hide();
            nextStep.show();
        }
    });

}
