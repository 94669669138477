import * as bootstrap from 'bootstrap';
import "@popperjs/core";
import $ from 'jquery';
import { types, calculatePrice, calculateDurationHours } from './price-builder';
import { toggleEditableFields, setJob, formatCurrency, togglePriceableDisplay, resetPriceableFields,counterHandler, updatePriceUI,createSlider } from './helper';
//import './MessagesApp';


var previousScroll = 0;
var priceContainerOffset = ($('#priceSummary').length > 0) ? $('#priceSummary').offset().top : 860;
$(window).scroll(function () {
    var currentScroll = $(this).scrollTop();

    if (currentScroll > previousScroll) {
        // Scrolling down
        $('.navbar').css('top', '-50px');  
        if (currentScroll > priceContainerOffset && $('#price').val() > 0) {
            $('#priceSummary').css('display', 'block').css('top', '14px');
        }
    } else {
        // Scrolling up
        $('.navbar').css('top', '0');
        if (currentScroll < priceContainerOffset) {
            $('#priceSummary').css('display', 'none').css('top', '60px');
        }
    }
    previousScroll = currentScroll;
});

 
//reset duration
$(document).ready(function () {
    var job = setJob();
    togglePriceableDisplay(job, types[$('#type').val()]);
    updatePriceUI(job, $('#price').val(), $('#duration').val());

    //Price Builder: Handle Type Fields
    $('#type').change(function () {

        console.log("Type Changed");

        var typeId = $(this).val();
        var type = types[typeId];

        togglePriceableDisplay(job, type);
        resetPriceableFields();

    });

    $('#priceInputs .price-items,#hourlyRate, #duration, #durationOccupants, #durationMax, #occupants').on('input', function () {

        // Create job object
        var job = setJob();
        var hourlyRate = parseFloat($('#hourlyRate').val(), 10) || parseFloat($('#hourlyRate').attr('min'), 10);
        var durationMax = parseFloat($('#durationMax').val(), 10) || 0;

        var duration = calculateDurationHours(job, priceItems);
       // var price = hourlyRate * duration || 0.00;

        // If types are not rental, duration is taken from the form
        switch (types[job.type]) {

            //if type is home get duration max for comparison
            case 'home':
                
                console.log("Duration Max:" + durationMax + " Duration: " + duration);

                //if duration is greater than duration max provide warning
                if (!durationMax || duration <= durationMax) {
                    $('#hoursExcededFeedback').hide();
                } else {
                    $('#hoursExcededFeedback').show();
                }

                break;

            //If type is rental then use regular price builder
            case 'rental':
                if (job.durationOccupants > 90) {
                    console.log("Switching to Long Term");
                    $('#type').val(12);
                } else {
                    $('#type').val(11);
                }
                break;

            case 'office':
                break;

        }
   
        var  price = calculatePrice(job, priceItems, hourlyRate) || 0;

        $('#price').val(price);
        $('#duration').val(duration); // + "hrs"

        updatePriceUI(job, price, duration);

        console.log("-----------------Price: " + formatCurrency(price) + "----------------------");
        console.log("Rate  + Duration : ", hourlyRate + " + " + duration);
        console.log("Job : ", job);

    });


    //Price Builder: Handle Status Fields
    toggleEditableFields($('#status').val());

    $('#status').change(function () {
        toggleEditableFields($('#status').val());
    });

    //Ui: Colapseble Card
    $('.fa-info-circle').click(function () {
        $(this).closest('.card').find('.collapse').collapse('toggle');
    });


    $('#proposalForm').on('submit', function (event) {
        var editorContent = tinymce.get('body').getContent();
        if (editorContent.trim() === '') {
            event.preventDefault();
            $('#proposalFeedback').text('This field is required.');
        } else {
            $('#proposalFeedback').text('');
        }
    });

    $('section.job-details #title').on('input', function () {

        $('#previewLinkContainer').removeClass('d-none');

        var title = $(this).val();
        var baseUrl = window.location.origin + '/jobs/';

        if (typeof title === 'string') {
            var slug = title.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/^-|-$/g, '');
            $('#preview-url').text(slug);
            $('#preview-link').attr('href', baseUrl + '/' + slug);
        }
    });

    var deleteAccountModal = $('#deleteAccountModal').modal({
        keyboard: false,
        show: false
    });

    var applyJobModal = $('#applyJobModal').modal({
        keyboard: false,
        show: false
    });


    var handleApplicationModal = $('#handleApplicationModal').modal({
        keyboard: false,
        show: false
    });

    if (typeof errorResponseCustom !== 'undefined' && errorResponseCustom.length > 0) {
        deleteAccountModal.modal('show');
        applyJobModal.modal('show');
        handleApplicationModal.modal('show');
    }



    $('.handle-application-button').on('click', function () {
        var jobId = $(this).data('job-id');
        var proposalId = $(this).data('proposal-id');
        $('#jobId').val(jobId);
        $('#proposalId').val(proposalId);
    });

    //UI: Modal

    $('[data-bs-toggle="modal"]').click(function (e) {
        e.preventDefault();
        var target = $(this).attr('data-bs-target');
        var $modalElement = $(target);
        var modal = bootstrap.Modal.getInstance($modalElement[0]);
        if (!modal) {
            modal = new bootstrap.Modal($modalElement[0]);
            $modalElement.data('bs.modal', modal);
        }
        modal.show();
    });

    $('[data-bs-toggle="modal"]').click(function () {
        var jobId = $(this).data('job-id');
        $('#jobId').val(jobId);
    });

    $('[data-bs-dismiss="modal"]').click(function () {
        var target = $(this).attr('data-bs-target');
        var $modalElement = $(target);
        var modal = bootstrap.Modal.getInstance($modalElement[0]);
        if (modal) {
            modal.hide();
        }
    });


    //UI: Logout 
    $("#logoutButton").click(function (event) {
        event.preventDefault();
        $(this).closest("form").submit();
    });

    //UI: Upload 
    $("#profileImageOverlay").click(function () {
        $("#profilePictureUpload").click();
    });
    $('#file-upload').on('change', function () {
        $('#upload-profile-picture').submit();
    });


    //UI: Counter 
    counterHandler();

    //UI Component: Date Picker
    var today = new Date().toISOString().split('T')[0];
    $('#startDate').prop('max', today);

    $('#starDate').change(function () {
        var startDate = $(this).val();
        $('#endDate').prop('min', startDate);
    });

    //Component: Hourly Rate  
    $('#rateValue').text((Math.round($('#hourlyRate').val() * 10) / 10).toFixed(2));

    // Update the displayed value whenever the range input changes
    $('#hourlyRate').on('input', function () {
        $('#rateValue').text((Math.round(this.value * 10) / 10).toFixed(2));
    });


    //UI: Slider
    createSlider();
 
});
