const minutes = {
    bedrooms: 30,
    bathrooms: 30,
    kitchens: 60,
    livingrooms: 60,
    terraces: 15,
    otherRooms: 60,
    extras: 120,
};
// enum JobType {
//   rental = '11',
//   rental = '11',
//   home = '12',
//   office = '13',
//   Other = '14'
// }
export const types = {
    "11": "rental",
    "12": "rental",
    "13": "home",
    "14": "office"
};
export function calculateDurationHours(job, priceItems) {
    let duration = 0;
    if (types[job.type] === 'office') {
        // If the job type is 'office', calculate the duration by dividing the square meters by 50
        duration = job.squareMeters / 50 * 60;
    }
    else {
        duration += job.bedrooms * minutes.bedrooms;
        duration += job.bathrooms * minutes.bathrooms;
        duration += job.livingrooms * minutes.livingrooms;
        duration += job.kitchens * minutes.kitchens;
        duration += job.terraces * minutes.terraces;
        duration += job.otherRooms * minutes.otherRooms;
        if (job.squareMeters) {
            let multiplier = Math.floor(job.squareMeters / 400);
            duration *= Math.pow(2, multiplier);
        }
    }
    //for each extra, 
    if (job.extras.length > 0) {
        //foreach extra find item in price data by id
        job.extras.forEach(extra => {
            var _a, _b, _c, _d;
            let extraData = priceItems.find(item => item.tag_id === Number(extra));
            if (extraData) {
                if (extraData.mins_occupant) {
                    //let occupants = job.occupants || 1;
                    duration += ((_a = extraData.mins_occupant) !== null && _a !== void 0 ? _a : 0) * job.occupants;
                    console.log("Extra " + extraData.tag_name + " By " + job.occupants + " Occupants:" + (((_b = extraData.mins_occupant) !== null && _b !== void 0 ? _b : 0) * job.occupants) + " +mins");
                }
                else if (extraData.is_square_meters) {
                    let squareMeters = job.squareMeters || 0; // Default to 0 if job.squareMeters is null
                    let multiplier = Math.floor(squareMeters / 400);
                    duration += ((_c = extraData.mins) !== null && _c !== void 0 ? _c : 0) * Math.pow(2, multiplier);
                    console.log("Extra " + extraData.tag_name + " By Square Meters:" + ((_d = extraData.mins) !== null && _d !== void 0 ? _d : 0) * Math.pow(2, multiplier) + "+mins");
                }
                else if (extraData.mins) {
                    duration += extraData.mins || 0; // Default to 0 if extraData.mins is null
                    console.log("Extra " + extraData.tag_name + " By Mins:" + (extraData.mins || 0));
                }
            }
        });
    }
    return duration / 60;
}
export function calculatePrice(job, priceItems, hourlyRate = 8) {
    let duration = calculateDurationHours(job, priceItems);
    let price = 0;
    if (job.hasProducts === 1) {
        price += 10;
    }
    price += duration * hourlyRate;
    return price;
}
